import React, { useEffect, useRef, useState } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import { KeyboardVoiceRounded, MicOffRounded, VideocamRounded, VideocamOffRounded, CallEndRounded } from '@mui/icons-material';
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';


function AgoraVideoCall({
  userInfo,
  callingDeviceId,
  callingAccountId,
  callerName,
  onClose,
  localVideoTrack,
  localAudioTrack,
  initialCameraEnabled,
  initialMicrophoneEnabled,
}) {
  const [joined, setJoined] = useState(false);
  const [remoteUsers, setRemoteUsers] = useState([]);
  const [isCameraEnabled, setIsCameraEnabled] = useState(initialCameraEnabled);
  const [isMicrophoneEnabled, setIsMicrophoneEnabled] = useState(initialMicrophoneEnabled);
  const [localVideoEnabled, setLocalVideoEnabled] = useState(initialCameraEnabled);
  const clientRef = useRef(null);
  const isJoiningRef = useRef(false);
  const localUserIdRef = useRef(null);
  const localVideoRef = useRef(null);
  const [remoteCameraStates, setRemoteCameraStates] = useState({});
  const remoteVideoRefs = useRef({});
  const aspectRatio = 16 / 9;
  const [showAlert, setShowAlert] = useState(false);
  const [callState, setCallState] = useState('waiting');
  useEffect(() => {
    startVideoCall();

    return () => {
      cleanupResources();
    };
  }, []);


  useEffect(() => {
    if (joined && remoteUsers.length === 0 && callState === 'connected') {
      // Only show alert and end call if we were previously connected
      setShowAlert(true);
      setCallState('ending');
      const timer = setTimeout(() => {
        handleLeaveChannel();
      }, 5000); // 5 seconds delay

      return () => clearTimeout(timer);
    } else if (joined && remoteUsers.length > 0) {
      setCallState('connected');
    }
  }, [joined, remoteUsers, callState]);


  const handleUserJoined = (user) => {
    setRemoteUsers((prevUsers) => {
      if (!prevUsers.some(u => u.uid === user.uid)) {
        return [...prevUsers, user];
      }
      return prevUsers;
    });
    setCallState('connected');
  };

  const handleUserLeft = (user) => {
    setRemoteUsers((prevUsers) => prevUsers.filter((u) => u.uid !== user.uid));
    setRemoteCameraStates((prev) => {
      const newState = { ...prev };
      delete newState[user.uid];
      return newState;
    });
  };

  useEffect(() => {
    if (localVideoRef.current && localVideoTrack) {
      if (isCameraEnabled) {
        try {
          localVideoTrack.play(localVideoRef.current);
          setLocalVideoEnabled(true);
        } catch (error) {
          console.error("Failed to play local video track:", error);
          setLocalVideoEnabled(false);
        }
      } else {
        localVideoTrack.stop();
        setLocalVideoEnabled(false);
      }
    }
  }, [localVideoTrack, isCameraEnabled]);

  useEffect(() => {
    if (localVideoTrack && clientRef.current && joined) {
      handleTrackPublishing(localVideoTrack, isCameraEnabled);
    }
  }, [isCameraEnabled, localVideoTrack, joined]);

  useEffect(() => {
    if (localAudioTrack && clientRef.current && joined) {
      handleTrackPublishing(localAudioTrack, isMicrophoneEnabled);
    }
  }, [isMicrophoneEnabled, localAudioTrack, joined]);

  const handleTrackPublishing = async (track, isEnabled) => {
    if (!clientRef.current || !joined) return;

    try {
      if (isEnabled) {
        await track.setEnabled(true);
        await clientRef.current.publish(track);
        console.log(`${track.trackMediaType} track published`);
      } else {
        await clientRef.current.unpublish(track);
        await track.setEnabled(false);
        console.log(`${track.trackMediaType} track unpublished`);
      }
    } catch (error) {
      console.error(`Failed to ${isEnabled ? 'publish' : 'unpublish'} ${track.trackMediaType} track:`, error);
    }
  };

  const cleanupResources = async () => {
    console.log("Cleaning up resources...");
    if (clientRef.current) {
      await clientRef.current.leave();
      clientRef.current.removeAllListeners();
      clientRef.current = null;
    }
    setRemoteUsers([]);
    localUserIdRef.current = null;
    setJoined(false);
    console.log("Resources cleaned up");
  };

  async function startVideoCall() {
    if (isJoiningRef.current || localUserIdRef.current) {
      return;
    }

    isJoiningRef.current = true;

    try {
      await fetch(
        `${process.env.REACT_APP_DEVICE_API_URL}/call_devices/${callingDeviceId}?CallerName=${encodeURIComponent(userInfo.name)}`
      );

      const tokenResponse = await fetch(
        `${process.env.REACT_APP_DEVICE_API_URL}/jwt/${callingDeviceId}`
      );
      const tokenData = await tokenResponse.json();
      const { token } = tokenData;

      const client = AgoraRTC.createClient({ codec: "vp8", mode: "rtc" });
      clientRef.current = client;

      client.on("user-published", handleUserPublished);
      client.on("user-unpublished", handleUserUnpublished);
      client.on("user-joined", handleUserJoined);
      client.on("user-left", handleUserLeft);
      await client.join(
        "c909117c706944bb8c9fdeeaaca70df6",
        callingAccountId,
        token,
        null
      );

      localUserIdRef.current = client.uid;
      setJoined(true);

      if (isCameraEnabled && localVideoTrack) {
        await handleTrackPublishing(localVideoTrack, true);
      }
      if (isMicrophoneEnabled && localAudioTrack) {
        await handleTrackPublishing(localAudioTrack, true);
      }
    } catch (error) {
      console.error("Failed to join channel", error);
    }

    isJoiningRef.current = false;
  }

  const handleUserPublished = async (user, mediaType) => {
    await clientRef.current.subscribe(user, mediaType);
    if (mediaType === "video") {
      setRemoteUsers((prevUsers) => {
        if (!prevUsers.some(u => u.uid === user.uid)) {
          return [...prevUsers, user];
        }
        return prevUsers;
      });
      setRemoteCameraStates(prev => ({ ...prev, [user.uid]: true }));
      if (remoteVideoRefs.current[user.uid]) {
        user.videoTrack.play(remoteVideoRefs.current[user.uid]);
      }
    }
    if (mediaType === "audio") {
      user.audioTrack.play();
    }
  };

  const handleUserUnpublished = (user, mediaType) => {
    if (mediaType === "video") {
      setRemoteCameraStates(prev => ({ ...prev, [user.uid]: false }));
      if (user.videoTrack) {
        user.videoTrack.stop();
      }
    }
    if (mediaType === "audio") {
      if (user.audioTrack) {
        user.audioTrack.stop();
      }
    }
  };

  const handleLeaveChannel = async () => {
    console.log("Leaving channel...");
    try {
      await cleanupResources();
      onClose();
    } catch (error) {
      console.error("Failed to leave channel", error);
    }
  };

  const toggleCamera = async () => {
    if (localVideoTrack) {
      const newState = !isCameraEnabled;
      setIsCameraEnabled(newState);
      
      try {
        await handleTrackPublishing(localVideoTrack, newState);
        
        if (newState) {
          if (localVideoRef.current) {
            localVideoTrack.play(localVideoRef.current);
            setLocalVideoEnabled(true);
          } else {
            console.warn("Local video container is not ready");
            setLocalVideoEnabled(false);
          }
        } else {
          localVideoTrack.stop();
          setLocalVideoEnabled(false);
        }
      } catch (error) {
        console.error("Failed to toggle camera:", error);
        setIsCameraEnabled(!newState); // Revert the state if there's an error
        setLocalVideoEnabled(!newState);
      }
    }
  };

  const toggleMicrophone = async () => {
    if (localAudioTrack) {
      const newState = !isMicrophoneEnabled;
      setIsMicrophoneEnabled(newState);
      await handleTrackPublishing(localAudioTrack, newState);
    }
  };

  

  const renderVideoContainer = (videoTrack, label, isLocal = false) => (
    <div style={{ 
      width: isLocal ? "25%" : "100%", 
      height: isLocal ? "25%" : "100%",
      position: isLocal ? "absolute" : "relative",
      top: isLocal ? "20px" : "auto",
      right: isLocal ? "20px" : "auto",
      zIndex: isLocal ? 2 : 1,
      borderRadius: "12px",
      overflow: "hidden",
      boxShadow: isLocal ? "0 4px 12px rgba(0,0,0,0.2)" : "none",
    }}>
      <div
        style={{
          backgroundColor: "#1a1a1a",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: "hidden",
          borderRadius: "12px",
        }}
      >
        <p
          style={{
            position: "absolute",
            top: "10px",
            left: "10px",
            color: "white",
            fontSize: isLocal ? "12px" : "16px",
            margin: 0,
            zIndex: 3,
            backgroundColor: "rgba(0,0,0,0.5)",
            padding: "5px 10px",
            borderRadius: "20px",
          }}
        >
          {label}
        </p>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          {isLocal ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                display: localVideoEnabled ? "block" : "none",
              }}
              ref={localVideoRef}
            />
          ) : (
            <div
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              ref={(ref) => {
                if (ref && videoTrack) {
                  remoteVideoRefs.current[videoTrack.getUserId()] = ref;
                  videoTrack.play(ref);
                }
              }}
            />
          )}
          {(!isLocal && !remoteCameraStates[videoTrack?.getUserId()]) || (isLocal && !localVideoEnabled) ? (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#2c3e50",
                color: "white",
                fontSize: isLocal ? "14px" : "24px",
                fontWeight: "bold",
              }}
            >
              Camera Off
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );

  const buttonStyle = (enabled) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50px",
    height: "50px",
    margin: "0 10px",
    borderRadius: "50%",
    border: "none",
    cursor: "pointer",
    transition: "all 0.3s ease",
    backgroundColor: enabled ? "#1976D2" : "#1976D2",
    boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
    color: "white",
  });

  return (
    <div style={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      backgroundColor: "#000",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}>
      <div style={{
        position: "relative",
        width: "100%",
        height: "100%",
        maxWidth: `${16 / 9 * 100}vh`,
        maxHeight: `${9 / 16 * 100}vw`,
        backgroundColor: "#000",
        overflow: "hidden",
      }}>
        {remoteUsers.length > 0 ? (
          remoteUsers.map((user) => renderVideoContainer(user.videoTrack, `${user.uid}`))
        ) : (
          <div style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontSize: "24px",
          }}>
            {callState === 'waiting' ? "Waiting for target user to join..." : "The other participant has left the call."}
          </div>
        )}
        {renderVideoContainer(localVideoTrack, `${userInfo.name} (You)`, true)}
        <div style={{
          position: "absolute",
          bottom: "20px",
          left: "50%",
          transform: "translateX(-50%)",
          display: "flex",
          justifyContent: "center",
          padding: "10px",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          borderRadius: "30px",
          zIndex: 3,
        }}>
          <button onClick={toggleMicrophone} style={buttonStyle(isMicrophoneEnabled)}>
            {isMicrophoneEnabled ? <KeyboardVoiceRounded /> : <MicOffRounded />}
          </button>
          <button onClick={toggleCamera} style={buttonStyle(isCameraEnabled)}>
            {isCameraEnabled ? <VideocamRounded /> : <VideocamOffRounded />}
          </button>
          <button onClick={handleLeaveChannel} style={{
            ...buttonStyle(true),
            backgroundColor: "rgba(211, 47, 47, 0.8)",
          }}>
            <CallEndRounded />
          </button>
        </div>
      </div>
      <Dialog
        open={showAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Call Ending"}</DialogTitle>
        <DialogContent>
          <Typography>
            The other participant has left the call. The video call will close in 5 seconds.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLeaveChannel} color="primary" autoFocus>
            Close Now
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AgoraVideoCall;