import React from 'react';
import logo from '../images/logo.png';
import carewindow from '../images/carewindow.png';
import { Link } from 'react-router-dom';

function Signup() {
  return (
    <div className="signup-container">
      <div className="signup-header">
        <img className="login-logo" src={logo} alt="Logo" />
        <img className="login-carewindow" src={carewindow} alt="CareWindow" />
      </div>
      <t className="user-info-text">Please enter your user information.</t>
      <form className="signup-form" id="signupForm" method="post" action="/signup">
        <t>Username</t>
        <input type="text" id="username" placeholder="" required />
        <t>E-mail</t>
        <input type="text" id="email" placeholder="" required />
        <t>Password</t>
        <input type="password" id="password" placeholder="" required />
        <t>Confirm</t>
        <input type="text" id="confirm" placeholder=" " required />
        <label>
          <input type="checkbox" name="remember" />
          <t className ="signup-agree">
            By creating an account, you agree the{' '}
            <a href="/" className="signup-terms">
               terms and conditions
            </a>
            .{' '}
          </t>
        </label>
        <button type="submit">Signup</button>
      </form>
      <div className="signup-form-links">
        <Link className='signup-login' to="/">Already member? Login</Link>
        <Link to="/reset" style={{ color: '#1E293B' }}>Forgot Password</Link>
      </div>
    </div>
  );
}

export default Signup;