import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './js/login';
import Signup from './js/signup';
import Reset from './js/resetpwd';
import Organise from './js/jac';
import SendEmail from './js/send_email';
import Userboard from './js/userboard';
import VideoCallPage from './js/videoCall';


function App() {
  return (
   
      <Router>
        <div>
          <Routes>
            <Route exact path="/" element={<Login />} />
          
              <Route path="/signup" element={<Signup />} />
              <Route path="/reset" element={<Reset />} />
              <Route path="/send_email" element={<SendEmail />} />
              <Route path='/organise' element={<Organise />} />
              <Route path='/userboard/:orgGuid' element={<Userboard />} />
              <Route path="/video-call" element={<VideoCallPage />} />
          </Routes>
        </div>
      </Router>
    
  );
}

export default App;