import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AgoraRTC from "agora-rtc-sdk-ng";
import AgoraVideoCall from './AgoraVideoCall';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function VideoCallPage() {
    const [callData, setCallData] = useState(null);
    const [localTracks, setLocalTracks] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
  
    useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      const encodedData = searchParams.get('data');
      if (encodedData) {
        try {
          const decodedData = JSON.parse(atob(encodedData));
          setCallData(decodedData);
          initLocalTracks(decodedData.initialCameraEnabled, decodedData.initialMicrophoneEnabled);
        } catch (error) {
          console.error('Failed to decode call data:', error);
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
  
      return () => {
        if (localTracks) {
          localTracks.audioTrack.stop();
          localTracks.audioTrack.close();
          localTracks.videoTrack.stop();
          localTracks.videoTrack.close();
        }
      };
    }, [location]);
  
    const initLocalTracks = async (cameraEnabled, microphoneEnabled) => {
      try {
        const audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
        const videoTrack = await AgoraRTC.createCameraVideoTrack();
        
        await audioTrack.setEnabled(microphoneEnabled);
        await videoTrack.setEnabled(cameraEnabled);
  
        setLocalTracks({ audioTrack, videoTrack });
        setIsLoading(false);
      } catch (error) {
        console.error('Failed to create local tracks:', error);
        setIsLoading(false);
      }
    };
  
    if (isLoading) {
      return (
        <Box 
          display="flex" 
          flexDirection="column"
          justifyContent="center" 
          alignItems="center" 
          minHeight="100vh"
          bgcolor="black"
        >
          <CircularProgress color="primary" size={60} thickness={4} />
          <Typography color="primary" style={{ marginTop: '20px' }}>
            Loading...
          </Typography>
        </Box>
      );
    }
  
    if (!callData || !localTracks) {
      return (
        <Box 
          display="flex" 
          justifyContent="center" 
          alignItems="center" 
          minHeight="100vh"
          bgcolor="black"
          color="white"
          fontSize="1.2rem"
        >
          Failed to initialize video call. Please try again.
        </Box>
      );
    }
  
    return (
      <div style={{ width: '100vw', height: '100vh', backgroundColor: 'black' }}>
        <AgoraVideoCall
          userInfo={callData.userInfo}
          callingDeviceId={callData.callingDeviceId}
          callingAccountId={callData.callingAccountId}
          callerName={callData.callerName}
          onClose={() => window.close()}
          localVideoTrack={localTracks.videoTrack}
          localAudioTrack={localTracks.audioTrack}
          initialCameraEnabled={callData.initialCameraEnabled}
          initialMicrophoneEnabled={callData.initialMicrophoneEnabled}
        />
      </div>
    );
  }
  
export default VideoCallPage;